import styled from "styled-components";
import { Link } from "gatsby";
import Bg from "assets/images/404-bg.jpg";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  position: relative;
  background-image: url(${Bg});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    background-color: ${({ theme }) => theme.colors.purple};
    opacity: 0.7;
    width: 100%;
    height: 100%;
  }
`;

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 80%;
  height: 80%;
`;

export const Column = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 2;
`;

export const Code = styled.div`
  font-size: 30rem;
  letter-spacing: 1rem;
  font-weight: 900;
  color: #fff;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
    font-size: 20rem;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    font-size: 16rem;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.phone_big}) {
    font-size: 12rem;
    text-align: center;
  }
`;

export const Message = styled.div`
  font-size: ${({ theme }) => theme.fonts.s};
  font-weight: 500;
  color: ${({ theme }) => theme.colors.white};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    font-size: ${({ theme }) => theme.fonts.xs};
  }
`;

export const BackButton = styled(Link)`
  display: inline-block;
  background: #5d00e2;
  font-size: 2rem;
  margin: 30px 0;
  color: #fff;
  text-decoration: none;
  padding: 10px 40px;
  border-radius: 5px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: 0.4s all ease;
  &:hover {
    background-color: #fff;
    color: #5d00e2;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
    font-size: 1.5rem;
  }

  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.phone_big}) and (orientation: landscape) {
    margin-top: 20px;
  }
`;

export const Line = styled.div`
  position: relative;
  z-index: 2;
  text-align: center;
`;
