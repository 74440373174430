import React from "react";

import {
  BackButton,
  Code,
  Column,
  Container,
  Line,
  Message,
  Wrapper,
} from "./styles";

const ErrorPage = (props) => {
  return (
    <Container>
      <Wrapper>
        <Column>
          <Code>{props.code}</Code>
          <Line>
            <Message>{props.message}</Message>
            <BackButton to="/">Strona główna</BackButton>
          </Line>
        </Column>
      </Wrapper>
    </Container>
  );
};

export { ErrorPage };
